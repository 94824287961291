import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { Breadcrumb, Button, Flex, FloatButton, Image, Progress, Skeleton } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import type { FireworksHandlers } from "@fireworks-js/react";
import { Fireworks } from "@fireworks-js/react";
import API from "Api";
import { delay, findLast, orderBy, round, sample, uniqBy } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { GERMAN_VOICES, STATIC_URL, STORY_TASKS, TaskTypes, VOICES } from "App.constants";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ProgressType, SentenceType, StatusType, TaskType } from "App.types";
import ButtonClose from "Components/ButtonClose";
import { TaskComponents } from "Components/SentenceTask";
import GrammarPanel from "Components/GrammarPanel";
import useProgress from "Hooks/useProgress";
import { getNonEnglishAudioUrl, getSentenceAudioUrl } from "App.helpers";
import CompleteWindow from "Components/CompleteWindow";
import StoryTask from "Components/SentenceTask/StoryTask";
import styles from "./Lesson.module.scss";
import cx from "classnames";
import { UserContext } from "App";
import { isMobile } from "react-device-detect";
import { CheckOutlined, HomeOutlined, StepBackwardOutlined, StepForwardOutlined, ZoomInOutlined } from "@ant-design/icons";
import VideoPlayer from "Components/SentenceTask/VideoPlayer";
import { useAllTasks } from "../useAllTasks";
import { useTranscripts } from "Hooks/useTranscripts";
import HintDrawer from "Components/HintDrawer";
import Dictionary from "Components/Dictionary";
import { Element, scroller } from "react-scroll";
import VideoPlayerStudio from "Components/SentenceTask/VideoPlayerStudio";

import "./Lesson.css";

const Lesson = () => {
  const [lessonStatus, setLessonStatus] = useState<StatusType>();
  const [activeIdx, setActiveIdx] = useState(0);
  const [taskStatus, setTaskStatus] = useState<StatusType>();
  const [isDictOpened, setDictOpened] = useState(false);
  const [isGrammarOpened, setGrammarOpened] = useState(false);
  const [tries, setTries] = useState(1);
  const [audioIndex, setAudioIndex] = useState(0);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();
  const [infoDrawerStatus, setInfoDrawerStatus] = useState<"closed" | "active" | "">();
  const [allTasks, setAllTasks] = useState<SentenceType[]>([]);

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { id = "", course, sentId } = useParams();

  const user = useContext(UserContext);

  const { data: lesson } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["lesson", id],
    queryFn: () => API.lesson.get(id),
  });

  const { data: lessons = [] } = useSuspenseQuery({
    queryKey: ["lessons", "course", course],
    queryFn: () => API.lesson.getList(course),
  });

  const { data: allLessonProg } = useSuspenseQuery({
    queryKey: ["progress", course],
    queryFn: () => API.progress.getForCourse(course),
    select: (data) => data.reduce<{ [id: number]: ProgressType }>((acc, value) => ({ ...acc, [value?.lesson?.id || 0]: value }), {}),
  });

  const lessonsOnly = useMemo(() => lessons.filter((l) => l.type !== "module" && !l.optional), [lessons]);
  const prevLesson = findLast(lessonsOnly, (l) => l.order < lesson.order);

  const isLocked =
    !user?.isAdmin &&
    prevLesson &&
    allLessonProg?.[prevLesson.id]?.status !== "completed" &&
    (allLessonProg?.[prevLesson.id]?.tries ?? 0) < 2;

  const {
    story: { type, id: storyId },
  } = lesson || { story: {} };

  const { data: sentences } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["sentences", storyId],
    queryFn: () => API.sentence.getListByStoryId(storyId),
  });

  const onRepeat = useCallback(() => {
    API.progress.delete(id).then(() => {
      queryClient.invalidateQueries({ queryKey: ["progress", +id] });
    });

    setLessonStatus(undefined);
  }, [id, queryClient]);

  const {
    showProgressModal,
    progress,
    contextHolder,
    refetch: refetchProgress,
  } = useProgress({ lesson: sentId ? undefined : lesson, onRepeat });

  const allTasksArray = useAllTasks(sentences, state?.allTasks);

  useEffect(() => {
    const allTasksTo = orderBy<[SentenceType & { isCompleted: boolean }]>(
      allTasksArray.map((s: any) => ({
        ...s,
        isCompleted: progress?.some((pr) => pr.sentence && pr.sentence.id === s?.id && pr.type === s.task),
      })),
      ["isCompleted"],
      ["desc"],
    );
    // @ts-ignore
    const nextIdx = allTasksTo.findIndex((s) => !s.isCompleted);
    if (nextIdx !== -1) {
      // @ts-ignore
      setAllTasks(allTasksTo);
      setActiveIdx(nextIdx);
    }
  }, [allTasksArray, progress]);

  useEffect(() => {
    if (sentId) {
      API.progress
        .delete(lesson.id)
        .then(() => {
          const order = sentences.find((s) => s.id === +sentId)?.order || -1;
          const completed = allTasks.filter((s: any, idx) => (state?.allTasks ? idx < +sentId : s?.lessonOrder < order));
          return Promise.all(
            completed.map((sent) =>
              API.progress.save({ lesson: { id: lesson.id }, tries: 1, type: sent?.task, sentence: { id: sent?.id ?? 0 } }),
            ),
          );
        })
        .then(() => navigate(`/course/${course}/lessons/exercise/${lesson.id}`, { replace: true }))
        .then(() => refetchProgress());
    }
  }, [allTasks, course, lesson.id, navigate, sentId, refetchProgress, sentences, state?.allTasks]);

  const activeSentence = useMemo<SentenceType | undefined>(() => allTasks[activeIdx], [allTasks, activeIdx]);

  const percent = useMemo<number>(() => {
    if (lessonStatus === StatusType.Completed) return 100;

    const tasksCount = allTasks.length;
    return round((100 / tasksCount) * activeIdx, 2);
  }, [allTasks.length, lessonStatus, activeIdx]);

  const { mutateAsync, mutate } = useMutation({
    mutationFn: API.progress.save,
    retry: 3,
  });

  const transcripts = useTranscripts({ sentence: activeSentence, speechRate: lesson.speechRate, audioIndex });

  const audios = useMemo(() => {
    if (!activeSentence?.text || activeSentence.task === TaskType.Grammar) return;

    const { text, voice, speak } = activeSentence;
    const isCard = (activeSentence.isCard || type === "card") && !text.includes(" ");

    const rate = lesson.speechRate || "slow";

    let result: HTMLAudioElement[];

    if (!user?.isEnglish) {
      const audio1 = new Audio(getNonEnglishAudioUrl(text.trim(), GERMAN_VOICES[0], storyId, rate));
      const audio2 = new Audio(getNonEnglishAudioUrl(text.trim(), GERMAN_VOICES[1], storyId, rate));

      audio1.addEventListener("play", () => audio2.pause());
      audio2.addEventListener("play", () => audio1.pause());

      result = [audio1, audio2, audio1, audio2];
    } else {
      const audio1 = new Audio(getSentenceAudioUrl(activeSentence.id, speak || text.trim(), voice || VOICES[6].value, rate));
      const audio2 = new Audio(getSentenceAudioUrl(activeSentence.id, speak || text.trim(), voice || VOICES[7].value, rate));

      audio1.addEventListener("play", () => audio2.pause());
      audio2.addEventListener("play", () => audio1.pause());

      result = [audio1, audio2];
    }

    const setNextIdx = () => setAudioIndex((prev) => (prev === (isCard ? 3 : 1) ? 0 : prev + 1));

    result.forEach((audio) => {
      (audio as HTMLAudioElement).addEventListener("ended", setNextIdx);
      // (audio as HTMLAudioElement).addEventListener("pause", setNextIdx);
    });

    return result;
  }, [activeSentence, type, lesson.speechRate, user?.isEnglish, storyId]);

  const TaskComponent = activeSentence?.task && TaskComponents[activeSentence.task];

  const title = useMemo(
    () => activeSentence?.description?.trim() || activeSentence?.grammar?.title || TaskTypes[activeSentence?.task || ""]?.desc,
    [activeSentence?.description, activeSentence?.grammar?.title, activeSentence?.task],
  );

  const fireworkRef = useRef<FireworksHandlers>(null);

  const onTaskComplete = useCallback(() => {
    setAudioIndex(0);
    setTaskStatus(StatusType.Completed);

    setHintDrawerStatus("closed");
    if (activeSentence?.info) {
      delay(() => setInfoDrawerStatus("active"), 500);
    }

    if (tries === 1 && activeSentence?.markers.includes("fireworks")) {
      fireworkRef?.current?.start();
      delay(() => fireworkRef?.current?.waitStop(), 3000);
    }
    setTries(1);

    const result = { sentence: { id: activeSentence?.id || 0 }, lesson: { id: +id }, type: activeSentence?.task, tries };
    mutateAsync(result);
    // queryClient.setQueryData(["progress", lesson.id], (data: []) => [...data, result]);

    if (activeIdx < allTasks.length - 1) {
      return mutate({
        lesson: { id: +id },
        percent: round((100 / allTasks.length) * (activeIdx + 1), 2),
        status: "progress",
      });
    } else {
      const tasksWithErrors = uniqBy(progress?.filter((pr) => pr.tries !== 1), (pr) => `${pr.sentence.id}${pr.type}`).length;
      const stars = ((allTasks.length - tasksWithErrors) / allTasks.length) * 5;

      return mutate({
        lesson: { id: +id },
        percent: 100,
        stars,
        status: "completed",
      });
    }
  }, [activeIdx, activeSentence, allTasks.length, id, mutate, mutateAsync, progress, tries]);

  const onNextTask = useCallback(() => {
    fireworkRef?.current?.waitStop();
    setHintDrawerStatus("");
    setInfoDrawerStatus("");
    setTaskStatus(StatusType.Empty);

    setAllTasks((prev) => prev.map((s) => (s.id === activeSentence?.id ? { ...s, isCompleted: true } : s)));

    if (activeIdx < allTasks.length - 1) {
      setActiveIdx((prev) => prev + 1);
    } else {
      setLessonStatus(StatusType.Completed);
    }
  }, [activeSentence?.id, activeIdx, allTasks.length]);

  const completeLesson = useCallback(() => {
    mutate({
      lesson: { id: +id },
      percent: 100,
      status: "completed",
    });

    setLessonStatus(StatusType.Completed);
  }, [id, mutate]);

  const setTry = useCallback((isHint: boolean) => {
    if (isHint) {
      setTries(0);
    } else {
      setTries((prev) => (prev ? prev + 1 : prev));
    }
  }, []);

  useEffect(() => {
    if (lessonStatus === StatusType.Completed) {
      fireworkRef?.current?.start();
      delay(() => fireworkRef?.current?.waitStop(), sample([3000, 4000, 5000]));
    }
  }, [lessonStatus]);

  useEffect(() => {
    delay(() => scroller.scrollTo("header", {}), 500);
  }, [activeSentence?.id]);

  useEffect(() => {
    let delayId = 0;
    if (activeSentence?.markers.includes("showGrammar") && !showProgressModal) {
      setGrammarOpened(true);
    } else {
      setGrammarOpened(false);
    }
    if (activeSentence?.hint && !showProgressModal) {
      delayId = delay(() => setHintDrawerStatus((prev) => (prev === "closed" ? prev : "active")), 1000);
    } else {
      clearTimeout(delayId);
      setHintDrawerStatus((prev) => (prev === "closed" ? prev : ""));
    }
    return () => (delayId ? clearTimeout(delayId) : undefined);
  }, [activeSentence?.hint, activeSentence?.info, activeSentence?.markers, showProgressModal]);

  const goBack = useCallback(() => {
    if (activeSentence)
      API.progress
        .deleteTask({ sentenceId: activeSentence.id, lessonId: lesson.id, task: activeSentence.task })
        .then(() => setActiveIdx((prev) => prev - 1));
  }, [activeSentence, lesson.id]);

  const goForward = useCallback(() => {
    onTaskComplete();
    onNextTask();
  }, [onNextTask, onTaskComplete]);

  useEffect(() => {
    if (isLocked) {
      navigate("/");
    }
  }, [isLocked, navigate]);

  const mediaComponent = () => {
    return activeSentence?.media?.includes(".mp3") ? (
      <audio controls src={`${STATIC_URL}/media/${activeSentence.media}`}></audio>
    ) : (
      activeSentence?.media && !isMobile && (
        <Image
          preview={
            !isMobile && {
              toolbarRender: () => null,
              mask: <ZoomInOutlined style={{ fontSize: 24 }} />,
              maskClassName: styles.mask,
            }
          }
          rootClassName={styles.childrenImage}
          onError={(e: any) => (e.target.style.visibility = "hidden")}
          src={`${STATIC_URL}/media/${activeSentence?.media}`}
          // style={{ backgroundImage: `url(${STATIC_URL}/media/${activeSentence.media})` }}
          alt={""}
          height={"100%"}
        />
      )
    );
  };

  console.log({ allTasks, activeIdx, activeSentence });

  return (
    <div className={cx(styles.lesson, { [styles.lesson__isMobile]: isMobile })}>
      <Element name={"header"} />

      {!isMobile && (
        <Breadcrumb
          items={[
            { title: <Button type={"link"} size={"small"} icon={<HomeOutlined />} onClick={() => navigate("/")}></Button> },
            { title: <Link to={`/course/${course}`}>{course}</Link> },
            { title: lesson.story.title },
          ]}
        />
      )}
      <div className={styles.header}>
        {lessonStatus !== StatusType.Completed && (
          <Flex>
            <Progress percent={round(percent)} showInfo={false} />
            <ButtonClose path={course ? `/course/${course}` : "/"} lessonId={lesson.id} />
          </Flex>
        )}
      </div>

      <Skeleton active={!!sentId} loading={!!sentId}>
        <SwitchTransition>
          <CSSTransition key={`${activeSentence?.id}${activeSentence?.task}${progress?.length}`} timeout={500} classNames={"fade"}>
            <>
              {lessonStatus !== StatusType.Completed && isMobile && (
                <Image
                  rootClassName={styles.childrenImage}
                  preview={false}
                  onError={(e: any) => (e.target.style.visibility = "hidden")}
                  src={`${STATIC_URL}/media/${activeSentence?.media}`}
                  // style={{ backgroundImage: `url(${STATIC_URL}/media/${activeSentence.media})` }}
                  alt={""}
                  height={"100%"}
                />
              )}

              {lessonStatus !== StatusType.Completed && activeSentence?.mediaStory && activeSentence?.task !== TaskType.Video && (
                <VideoPlayerStudio story={activeSentence.mediaStory} showSubtitles={activeSentence.markers.includes("subtitles")} />
              )}

              {lessonStatus !== StatusType.Completed &&
                !!(activeSentence?.medias?.length || activeSentence?.media?.includes(".mp4")) &&
                activeSentence?.task !== TaskType.Video && (
                  <VideoPlayer
                    className={styles.video}
                    largeCaptionSize
                    showCaptions={
                      activeSentence.markers.includes("subtitles") ||
                      (activeSentence.markers.includes("subtitlesAfter") && taskStatus === StatusType.Completed)
                    }
                    medias={
                      activeSentence.medias.length
                        ? activeSentence.medias
                        : [
                            {
                              url: activeSentence.media,
                            },
                          ]
                    }
                    autoPlay={!sentId && activeSentence.task !== TaskType.Listen}
                    onFinish={() => setIsVideoPlayed(true)}
                  />
                )}

              <div className={styles.contentWrapper}>
                {title && activeSentence?.task !== TaskType.Intro && lessonStatus !== StatusType.Completed && (
                  <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
                )}

                <div
                  className={cx(styles.content, {
                    [styles.content__fullHeight]:
                      lesson.story.type === "dialog" ||
                      (activeSentence?.task && [TaskType.Grammar, TaskType.MaskedDragDrop].includes(activeSentence.task)) ||
                      activeSentence?.linkedStoryId,
                  })}
                >
                  {lessonStatus === "completed" ? (
                    <CompleteWindow sentences={sentences} onRepeat={onRepeat} lessonId={+id} course={course} />
                  ) : (
                    activeSentence &&
                    ((activeSentence?.task && STORY_TASKS.includes(activeSentence.task)) ||
                    lesson.story.type === "dialog" ||
                    (activeSentence.linkedStoryId && activeSentence.task === TaskType.Video) ? (
                      <StoryTask
                        activeType={activeSentence.task}
                        story={activeSentence.linkedStory || lesson.story}
                        lesson={lesson}
                        onTaskComplete={onTaskComplete}
                        onNext={onNextTask}
                        media={activeSentence.media}
                        markers={activeSentence.markers}
                        autoPlay={!sentId && !showProgressModal}
                      ></StoryTask>
                    ) : (
                      <TaskComponent
                        audio={audios?.[audioIndex]}
                        sentence={activeSentence}
                        transcripts={transcripts}
                        onNext={onNextTask}
                        activeType={activeSentence?.task}
                        onTaskComplete={onTaskComplete}
                        lesson={lesson}
                        setDictOpened={setDictOpened}
                        setTry={setTry}
                        showGrammar={(activeSentence?.grammar || lesson.grammar) && setGrammarOpened}
                        // alignCenter={type === "card" || activeSentence?.isCard}
                        alignCenter={true}
                        showAnswer={activeSentence?.task === TaskType.SpellerListen}
                        sentences={sentences}
                        showSuccessMessage
                        autoPlay={!sentId && !showProgressModal && (!activeSentence.medias.length || isVideoPlayed)}
                        noTranslate={activeSentence?.markers.includes("noTranslate")}
                        noText={activeSentence?.markers.includes("noText")}
                        completeLesson={completeLesson}
                      >
                        {mediaComponent()}
                      </TaskComponent>
                    ))
                  )}
                </div>
              </div>
            </>
          </CSSTransition>
        </SwitchTransition>
      </Skeleton>
      {contextHolder}

      <HintDrawer open={hintDrawerStatus === "active"} text={activeSentence?.hint} onClose={() => setHintDrawerStatus("closed")} />
      <HintDrawer text={activeSentence?.info} open={infoDrawerStatus === "active"}>
        <Button
          size={isMobile ? "middle" : undefined}
          onClick={onNextTask}
          icon={<CheckOutlined />}
          className={styles.btn_next}
          type={"primary"}
          shape={"round"}
        >
          далее
        </Button>
      </HintDrawer>

      <GrammarPanel isOpen={isGrammarOpened} grammar={activeSentence?.grammar || lesson?.grammar} toggle={setGrammarOpened} />
      <Fireworks
        ref={fireworkRef}
        autostart={false}
        options={{ acceleration: 1 }}
        style={{ position: "fixed", height: "100%", width: "100%", left: 0, top: 0, pointerEvents: "none", zIndex: "9" }}
      />

      {!activeSentence?.linkedStoryId && (
        <Dictionary
          isOpen={isDictOpened}
          storyId={lesson.story.id}
          sentences={
            type === "card" || (activeSentence?.task && [...STORY_TASKS].includes(activeSentence.task)) ? sentences : activeSentence
          }
          toggle={setDictOpened}
        />
      )}

      {user?.isAdmin && lessonStatus !== StatusType.Completed && (
        <FloatButton.Group
          shape={"square"}
          style={{
            insetInlineEnd: 0,
            insetBlockEnd: 66,
          }}
        >
          <FloatButton
            style={{
              pointerEvents: activeIdx && activeSentence ? undefined : "none",
            }}
            icon={
              <StepBackwardOutlined
                style={{
                  color: activeIdx && activeSentence ? undefined : "gray",
                }}
              />
            }
            onClick={goBack}
          />
          <FloatButton
            style={{
              pointerEvents: activeIdx > allTasks.length - 1 ? "none" : undefined,
            }}
            icon={
              <StepForwardOutlined
                style={{
                  color: activeIdx > allTasks.length - 1 ? "gray" : undefined,
                }}
              />
            }
            onClick={goForward}
          />
        </FloatButton.Group>
      )}
    </div>
  );
};

export default Lesson;
